import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {useAuth} from '../modules/auth'

const PrivateRoutes = () => {
  const {has_permission} = useAuth()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  /* custom component */
  const PersonasPage = lazy(() => import('../modules/apps/main-personas/PersonasPage'))
  const RecursosPage = lazy(() => import('../modules/apps/costo-recurso/RecursoPage'))
  const MatricesPage = lazy(() => import('../modules/apps/costo-matriz/MatrizPage'))
  const ProveedoresPage = lazy(() => import('../modules/apps/compra-proveedor/ProveedorPage'))
  const ClientesPage = lazy(() => import('../modules/apps/proyecto-cliente/ClientePage'))
  const EmpleadosPage = lazy(() => import('../modules/apps/nomina-empleado/EmpleadoPage'))
  const GruposPage = lazy(() => import('../modules/apps/nomina-grupo/GrupoPage'))
  const ProductoPage = lazy(() => import('../modules/apps/venta-productos/ProductoPage'))
  const VentaPage = lazy(() => import('../modules/apps/venta-venta/VentaPage'))
  const RemisionPage = lazy(() => import('../modules/apps/venta-remision/RemisionPage'))
  const ProyectosPage = lazy(() => import('../modules/apps/proyecto-proyecto/ProyectoPage'))
  const PreciosPage = lazy(() => import('../modules/apps/compra-precios/PrecioPage'))
  const OrdenesPage = lazy(() => import('../modules/apps/compra-orden/OrdenPage'))
  const PresupuestoPage = lazy(
    () => import('../modules/apps/costo-presupuesto/presupuesto-page/PresupuestoPage')
  )
  const RequerimientoPage = lazy(
    () => import('../modules/apps/compra-requerimiento/RequerimientoPage')
  )

  

  // Report parameter
  const ComprasParam = lazy(() => import('../pages/reports/compras/ComprasParams'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* CUSTOMS ROUTE */}
        <Route
          path='apps/personas/*'
          element={
            <SuspensedView>
              <PersonasPage />
            </SuspensedView>
          }
        />
        {has_permission('costo.view_recurso') && (
          <Route
            path='apps/recursos/*'
            element={
              <SuspensedView>
                <RecursosPage />
              </SuspensedView>
            }
          />
        )}

        <Route
          path='apps/matrices/*'
          element={
            <SuspensedView>
              <MatricesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/proveedores/*'
          element={
            <SuspensedView>
              <ProveedoresPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/clientes/*'
          element={
            <SuspensedView>
              <ClientesPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='apps/empleados/*'
          element={
            <SuspensedView>
              <EmpleadosPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='apps/proyectos/*'
          element={
            <SuspensedView>
              <ProyectosPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/productos/*'
          element={
            <SuspensedView>
              <ProductoPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ventas/*'
          element={
            <SuspensedView>
              <VentaPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/remisiones/*'
          element={
            <SuspensedView>
              <RemisionPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/empleados/*'
          element={
            <SuspensedView>
              <EmpleadosPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/grupos/*'
          element={
            <SuspensedView>
              <GruposPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/precios/*'
          element={
            <SuspensedView>
              <PreciosPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ordenes/*'
          element={
            <SuspensedView>
              <OrdenesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/partidas/*'
          element={
            <SuspensedView>
              <PresupuestoPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/requerimientos/*'
          element={
            <SuspensedView>
              <RequerimientoPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/compras/params'
          element={
            <SuspensedView>
              <ComprasParam />
            </SuspensedView>
          }
        />
        {/* <Route
          path='apps/precios/*'
          element={
            <SuspensedView>
              <PreciosPage />
            </SuspensedView>
          }
        /> */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
