const QUERIES = {
  USERS_LIST: 'users-list',
  PERSONAS_LIST: 'personas-list',
  RECURSOS_LIST: 'recursos-list',
  MATRICES_LIST: 'matrices-list',
  PROVEEDORES_LIST: 'proveedores-list',
  CLIENTES_LIST: 'clientes-list',
  EMPLEADOS_LIST: 'empleados-list',
  GRUPOS_LIST: 'grupos-list',
  PROYECTOS_LIST: 'proyectos-list',
  PRECIOS_LIST: 'precios_list',
  ORDENES_LIST: 'ordenes_list',
  ORDENES_CONSULTA: 'ordenes_consulta',
  CONTACTOS_LIST: 'contactos_list',
  ADJUNTOS_LIST: 'adjuntos_list',
  COMENTARIOS_LIST: 'comentarios_list',
  BODEGAS_LIST: 'bodegas_list',
  SUCURSALES_LIST: 'sucursales_list',
  REQUERIMIENTOS_LIST: 'requerimientos_list',
  PRODUCTOS_LIST: 'productos_list',
  UNIDADES_LIST: 'unidades_list',
  CATEGORIAS_LIST: 'categorias_list',
  SUBCATEGORIAS_LIST: 'subcategorias_list',
  VENTA_LIST: 'ventas_list',
  VENTA_CONSULTA: 'ventas_consulta',
  REMISION_LIST: 'remisiones_list',
}

export {QUERIES}
